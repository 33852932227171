import styled, { css } from "styled-components";
import { background, color } from "styled-system";

import { media, customMedia } from "../../common/MediaQueries";
import { TypeWriteWrapperStyled } from "../TypeWrite/styles";

const HeroStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1262px;
  margin: 0 auto;
  padding: 0 25px;
  z-index: 1;

  @media (min-width: 1240px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const HeroContentStyled = styled.div`
  max-width: 720px;
  padding: 0 0 30px;
  order: 2;

  @media (min-width: 1240px)
    order: 1;
    padding: 0;
  }
`;

const HeroContentSubheadStyled = styled.div`
  margin-bottom: 20px;
  font-size: 1.175rem; 
  font-weight: 300;
  line-height: 1.2;

  @media ${media.phone} {
    margin-bottom: 68px;
    font-size: 1.6rem
  }
`;

const HeroContentFooterStyled = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
  }
`;

const HeroContentCtaStyled = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const HeroMediaStyled = styled.div`
  width: 180px;
  order: 1;

  @media ${media.phone} {
    width: 440px;
  }

  @media (min-width: 1240px) {
    order: 2;
    margin-left: auto;
    width: 440px;
    height: 500px;
  }
`;

const HeroContentHeadStyled = styled.div`
  margin-bottom: 0.5rem;

  @media ${media.tablet} {
    margin-bottom: 1rem;
  }

  mark {
    display: inline-block;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    background-color: transparent;
    width: 223px;
    width: 18ch; 
    padding: 0 2px;
    margin: 0;
    max-width: 100%;

    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::after {
      left: -4px;
      top: 50%;
      width: 100%;
      height: 85%;
      background-image: linear-gradient(137deg, #ff5070 0%, #38b1eb 100%);
      z-index: -1;
      transform: translateY(-50%);
      @media ${customMedia.maxPhone} {
        left: 0;
      }
    }

    @media  { 
      width: 335px;
      width: 18ch; 
    }

    @media ${media.phone} {
      width: 335px;
      width: 18ch;
      padding: 0;
    }

    @media ${media.tablet} {
      width: 607px;
      width: 18ch;
    }
  }

  ${TypeWriteWrapperStyled} {
    span {
      font-weight: ${({ theme }) => theme.fontWeight.bold};

      &::after {
        width: 3px;
        height: 93%;
        bottom: 5px;
        margin-left: 3px;
        @media ${customMedia.maxPhone} {
          bottom: 3px;
        }
      }
    }
  }

  & h1 {
    font-family: "proxima-nova", sans-serif !important;
  }
`;

const HeroContainerStyled = styled.div`
  position: relative;
  ${background}
  ${color}
  width: 100%;
  padding: 38px 0 4px;
  z-index: 1;

  @media ${media.phone} {
    padding: 76px 0;
  }

  @media ${media.tablet} {
    padding: 76px 0 120px;
  }
`;

export {
  HeroContainerStyled,
  HeroStyled,
  HeroContentStyled,
  HeroContentHeadStyled,
  HeroContentSubheadStyled,
  HeroContentFooterStyled,
  HeroMediaStyled,
  HeroContentCtaStyled,
};
