import React from "react";
import type IStarpiImage from '../../interfaces/IStrapiImage';

import {
  HeroContainerStyled,
  HeroStyled,
  HeroContentStyled,
  HeroContentHeadStyled,
  HeroContentSubheadStyled,
  HeroContentFooterStyled,
  HeroMediaStyled,
  HeroContentCtaStyled,
} from "./styles";

import ScrollTo from "../ScrollTo";
import Image from "../Image";
import DottedBg from "../DottedBg";
import ButtonLink from "../ButtonLink";

interface IProps {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  image: IStarpiImage[];
  isStrapiImage?: boolean
  scrollText?: string;
  scrollTarget?: string;
  isDottedBg?: boolean;
  bg?: string;
  ctaText?: string;
  ctaUrl?: string;
  altText?: string;
  isHeadingCentered?: boolean;
}

const Hero: React.FC<IProps> = ({
  heading,
  subheading,
  image,
  altText,
  scrollText,
  scrollTarget,
  isDottedBg,
  isHeadingCentered,
  bg,
  ctaUrl,
  ctaText,
}) => (
  <HeroContainerStyled {...{ bg, isHeadingCentered }}>
    {isDottedBg && <DottedBg />}
    <HeroStyled>
      <HeroContentStyled>
        <HeroContentHeadStyled>
          {heading}
        </HeroContentHeadStyled>
        {!!subheading && (
          <HeroContentSubheadStyled>{subheading}</HeroContentSubheadStyled>
        )}
        {!!ctaUrl && (
          <HeroContentCtaStyled>
            <ButtonLink 
              as="a"
              internal
              big
              watermelon
              href={`#${ctaUrl}`}
            >
              {ctaText}
            </ButtonLink>
          </HeroContentCtaStyled>
        )}
        {!!scrollTarget && (
          <HeroContentFooterStyled>
            <ScrollTo title={scrollText || 'Scroll Down'} {...{ scrollTarget }} />
          </HeroContentFooterStyled>
        )}
      </HeroContentStyled>
      <HeroMediaStyled>
        <Image 
          fluid={image.map(image => image.localFile)}
          alt={altText || ''} 
        />
      </HeroMediaStyled>
    </HeroStyled>
  </HeroContainerStyled>
);

export default Hero;
