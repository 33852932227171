import styled, { css } from "styled-components";
import { flex, flexDirection, flexWrap, padding, width } from "styled-system";
import { customMedia, media } from "./../../common/MediaQueries";

const TextColumnItemStyled = styled.div`
  ${flex};
  ${width};
  ${padding}

  @media ${customMedia.maxPhone} {
    margin-bottom: 30px;
  }
`;

const TextColumnItemContentStyled = styled.div`
  ${padding};
  width: 100%;
`;

interface TextColumnItemHeadingStyledProps { 
  outline?: boolean
}

const TextColumnItemHeadingStyled = styled.div<TextColumnItemHeadingStyledProps>`
  span {
    color: ${({ theme }) => theme.colors.watermelon};
  }

  ${({ outline }) => outline && 
    css`i { color: ${({ theme }) => theme.colors.watermelon}; }`
  }
`;

const TextColumnItemCtaStyled = styled.div`
  margin-top: 20px;
  @media ${customMedia.maxPhone} {
    display: flex;
    justify-content: center;
  }
`;

const TextColumnItemTextStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.light};

  * {
    font-size: 1rem;
    line-height: 1.5;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const TextColumnStyled = styled.div`
  ${flexDirection};
  ${flexWrap};
  display: flex;
  width: 100%;
`;

export {
  TextColumnStyled,
  TextColumnItemStyled,
  TextColumnItemContentStyled,
  TextColumnItemHeadingStyled,
  TextColumnItemCtaStyled,
  TextColumnItemTextStyled,
};
