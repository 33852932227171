import React from "react";
import Slider from "react-slick";
import "./../Slick/vendor/slick-theme.scss";
import "./../Slick/vendor/slick.scss";

import useWindowSize from "../../hooks/windowSize";
import {
  ClientsListWrapperStyled,
  ClientsListStyled,
  ClientsListItemStyled,
  ClientsListItemMedia,
  ClientsListItemMediaImg,
} from "./styles";
import SliderWrapperStyled from "./../SliderWrapper";
import { IImage, ImageProperties } from "./../../interfaces/IImage";

export interface IClientItem {
  items: { 
    image: Pick<ImageProperties, 'sourceUrl' | 'altText'>
  }[];
  settings: object;
}

const MOBILE_BREAKPOINT = 768;

const Clients: React.FC<IClientItem> = ({ items, settings }) => {
  const { width: windowWidth } = useWindowSize();

  if (windowWidth >= MOBILE_BREAKPOINT) {
    return (
      <ClientsListWrapperStyled>
        <ClientsListStyled>
          {items.map((item, index) => (
            <ClientsListItemStyled key={index}>
              <ClientsListItemMedia>
                <ClientsListItemMediaImg
                  data-src={item.image.sourceUrl}
                  alt={item.image.altText}
                  className="lazyload blur-up"
                  width="150"
                  height="50"
                />
              </ClientsListItemMedia>
            </ClientsListItemStyled>
          ))}
        </ClientsListStyled>
      </ClientsListWrapperStyled>
    );
  }

  return (
    <ClientsListWrapperStyled>
      <SliderWrapperStyled>
        <Slider {...settings}>
          {items.map((item, index) => (
            <ClientsListItemMedia key={index}>
              <ClientsListItemMediaImg
                data-src={item.image.sourceUrl}
                alt={item.image.altText}
                className="lazyload blur-up"
                width="150"
                height="50"
              />
            </ClientsListItemMedia>
          ))}
        </Slider>
      </SliderWrapperStyled>
    </ClientsListWrapperStyled>
  );
};

export default Clients;
