import styled from "styled-components";

import { customMedia, media } from "../../common/MediaQueries";
import arrowIcon from "./../../assets/images/svg/slider-arrow-icon.svg";
import sliderArrow from "./../../assets/images/svg/slider-arrow-icon.svg";

const ClientsListWrapperStyled = styled.div`
  width: 100%;

  .swiper-button-prev,
  .swiper-button-next {
    background-image: url(${arrowIcon});
    top: 10px;
  }

  .swiper-pagination-bullet {
    background-color: ${({ theme }) => theme.colors.main};
  }
  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.main};
  }

  .slick-arrow {
    &:before {
      background-image: url(${sliderArrow});
    }
  }

  .slick-dots li button {
    background: ${({ theme }) => theme.colors.main};
  }

  .slicks-dots li.slick-active button {
    background: ${({ theme }) => theme.colors.main};
    opacity: 1;
  }

  .slick-prev,
  .slick-next {
    transform: none;
    top: 18%;
  }
`;

const ClientsListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 -0.625rem;
`;

const ClientsListItemStyled = styled.li`
  display: flex;
  justify-content: center;
  padding: 0.625rem;
  width: auto;
  margin-bottom: 1rem;

  @media ${media.phone} {
    width: calc(100% / 3);
    margin-bottom: 0;
  }

  @media ${media.tablet} {
    width: calc(100% / 5);
  }
`;

const ClientsListItemMedia = styled.div`
  display: flex;
  transition: 0.25s;
  width: auto;
  font-size: 72px;
  overflow: hidden;
  outline: 0;

  &:hover {
    opacity: 0.7;
  }

  > * {
    justify-content: center;
  }
`;

const ClientsListItemMediaImg = styled.img`
  height: 1em;
  @media ${customMedia.maxPhone} {
    margin: 0 auto;
  }
`;

export {
  ClientsListWrapperStyled,
  ClientsListStyled,
  ClientsListItemStyled,
  ClientsListItemMedia,
  ClientsListItemMediaImg,
};
