import styled from "styled-components";

const TypeWriteWrapperStyled = styled.span`
  display: inline-block;
  position: relative;
  color: ${({ theme }) => theme.colors.white};

  span {
    &::after {
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.colors.main};
      animation: styles_blink__2CKyC 0.75s infinite step-start;
    }
  }
`;

export { TypeWriteWrapperStyled };
