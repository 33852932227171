import React, { FC, ReactNode } from "react";

import Inner from "./../../components/Inner";

import Hero from "./../../components/Hero";
import TypeWrite from "./../../components/TypeWrite";
import { Heading20 } from "./../../components/Typography";
import IStrapiImage from "../../interfaces/IStrapiImage";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const Heading = styled.h1`
  font-size: 20px; 
  line-height: 1.2; 
  font-weight: 700;

  @media (min-width: 375px) { 
    font-size: 1.875rem;
  }

  @media ${media.phone} { 
    font-size: 3.4rem;
    line-height: 1.1; 
  }
`

const Subheading = styled.div`
  margin-top: 30px;
  line-height: 1.4;
`

const MarkWrapper = styled.span`
  display: inline-block; 
  position: relative; 
  padding-right: 5px;
`

const Comma = styled.span`
  position: absolute;
  right: 0; 
  top: 0; 
  width: 5px;
`

interface Props { 
  block: { 
    imageDesktop: IStrapiImage
    imageMobile: IStrapiImage
    titleTypingItem: { 
      text: string
    }[]
    titleFirstPart: string
    titleSecondPart: string
    subtitle: string
  }
  index: number
}

const FlexHeroHomepage: FC<Props> = ({ block, index }) => {
  const { 
    imageDesktop,
    imageMobile,
    subtitle,
    titleFirstPart,
    titleSecondPart,
    titleTypingItem,
  } = block;

  const addDurationToStepsArray: (arr: { text: string }[]) => (string|number)[] = (arr) => {
    return arr.reduce((acc, item) => {
      return [
        ...acc, 
        item.text, 
        2000,
      ]
    }, [] as (string|number)[]);
  };

  const imageAlt = imageDesktop.alternativeText || '';

  return (
    <Inner noOffsetMobile>
      <Hero
        heading={
          <Heading>
            {titleFirstPart}
            {" "}
            <MarkWrapper>
              <mark>
                  <TypeWrite
                    steps={addDurationToStepsArray(titleTypingItem)}
                  />
              </mark>
              <Comma>
                {","}
              </Comma>
            </MarkWrapper>
            {" "}
            {titleSecondPart}
          </Heading>
        }
        subheading={
          <Subheading
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        }
        image={[imageMobile, imageDesktop]}
        isStrapiImage={true}
        altText={imageAlt}
        scrollText="Scroll Down"
        scrollTarget="section-features"
      />
    </Inner>
  );
};

export default FlexHeroHomepage;

